import request from '../util/request';

function setUrlParam(data) {
  let _str = '';
  for (let i in data) {
    if (!isNull(data[i])) {
      _str += i + '=' + encodeURIComponent(data[i]) + '&';
    }
  }
  return '?' + _str.substring(0, _str.length - 1);
}

function isNull(e) {
  let _ = String(e);
  return (_ === 'null' || _ === 'NaN' || _ === 'undefined' || _.length === 0);
}


const api = {
  getWechatSDKSignature(data){
    return request({
      url: '/getWechatSDKSignature',
      method: 'POST',
      data:JSON.stringify(data)
    })
  },
  weatherjson(data) {
    return request({
      // url: 'http://111.9.253.19:8090/weatherkit/weatherjson/' + data,
      url: 'https://zylsweather.lintrondata.com/weatherkit/weatherjson/' + data,
      // ContentType: 'application/json;charset=UTF-8',
      method: 'get',
      // data:data,
    })
  },
  scenicState(data) {
    return request({
      url: 'http://www.zyou.cloud/bigdataapi/bigdata/api/zwyCont/scenicState.json',
      // ContentType: 'application/json;charset=UTF-8',
      method: 'get',
      // data:data,
    })
  },
  getAllArea(data) {
    return request({
      url: '/baseArea/getAllArea?adCode=' + data,
      method: 'get',
    })
  },
  queryBanner(data) {
    return request({
      url: '/banner/queryBanner'+setUrlParam(data),
      method: 'get',
    })
  },
  getAppMenu(data) {
    return request({
      url: '/baseMenu/getAppMenu'+setUrlParam(data),
      method: 'get',
    })
  },
  getAppMenuByWX(data) {
    return request({
      url: 'baseMenu/getAppMenuByWX?code=sycd_vx',
      method: 'get',
    })
  },
  slicedQueryVideo(data) {
    return request({
      url: '/video/findVideoList'+setUrlParam(data),
      method: 'GET',
      data:data,
    })
  },
  listTravelNewsFlash(data) {
    return request({
      url: '/content/listTravelNewsFlash'+setUrlParam(data),
      method: 'GET',
      data:data,
    })
  },
  queryGroupByGroupTypeId(data) {
    return request({
      url: '/group/queryGroupByGroupTypeId'+setUrlParam(data),
      method: 'GET',
      data:data,
    })
  },
  recommendList(data) {
    return request({
      url: '/recommend/list'+setUrlParam(data),
      method: 'GET',
      data:data,
    })
  },
  wxAuthorize(data) {
    return request({
      url: '/wxAuthorize'+setUrlParam(data),
      method: 'GET',
    })
  },
  getWechatUserInfo(data) {
    return request({
      url: '/getWechatUserInfo?code='+data,
      method: 'GET',
    })
  },
  findUserInfo(data) {
    return request({
      url: '/userInfo/findUserInfo',
      method: 'POST',
      data:data
    })
  },
  queryHotCommodity(data) {
    return request({
      url: '/commodity/queryHotCommodity',
      method: 'GET',
    })
  },
  queryComposeCommodity(data) {
    return request({
      url: '/commodity/queryComposeCommodity',
      method: 'GET',
    })
  },
  queryOddssaleCommodity(data) {
    return request({
      url: '/commodity/queryOddssaleCommodity',
      method: 'GET',
    })
  },
  queryOrderList(data) {
    return request({
      url: '/stlsOrder/queryOrderList',
      method: 'get',
      params:data,
    })
  },
  findByColumnCode(data) {
    return request({
      url: '/content/findByColumnCode?columnCode=important_notice',
      method: 'get',
    })
  },
};

export default api;
