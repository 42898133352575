module.exports = {
  // 服务器url
  // baseUrl: "http://10.8.117.143:9100/", //童工
  // baseUrl: "http://10.8.205.47:9100/", //杨磊
  // baseUrl: "http://10.8.117.177:9000/", //陈力
  // 测试环境
  baseUrl: "https://zylswxapics.lintrondata.com/",
  baseMallUrl: 'https://zylswxcs.lintrondata.com/shop/social_user_login/auto_sign_in?token=',
  appId: 'wx91502938dcafe62d',// 领创科技
  visitUrl: 'https://zylswxcs.lintrondata.com/',
  //   baseUrl: "http://111.9.253.7:8106/", 

  //正式
  //baseUrl: "https://intfm.63517.net/", //正式环境(需要确定是否是https)
  //baseMallUrl:'http://m.63517.net/shop/social_user_login/auto_sign_in?token=',
  //visitUrl: 'https://m.63517.net/',
  token: '', //用户token
  // 拿token
  //https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx91502938dcafe62d&redirect_uri=http%3A%2F%2Ftdllcs.lintrondata.com%2FgetWechatUserInfo&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect

  
  // token: "2d0a04e0-4960-44f1-b3b9-0f812221fe7a",//全局sessionStorage中存放用户TOKEN的变量名称 测试token huangwei
  // loactions: '', //城市gps
  cityId: '', //城市id
  chartColor: ['#00897B', '#2196f3', '#3f51b5', '#7b4ffc', '#ff54a8', '#e53935',
    '#8e24aa', '#009688', '#22bb87', '#8bc34a', '#cddc39', '#ffeb3b',
    '#ffc107', '#ff9800', '#ff5722', '#651fff', '#7c4dff', '#aa00ff',
    '#d81b60', '#512da8', '#00ac95', '#00c853', '#009688', '#0274a9',
    '#34be7c', '#43a047'
  ], //Echart图形自定义颜色
  projectYear: 2021, //项目库的年份，
  isAddPerson: false,
  audio: null,//音频
  shareTitle: '智游乐山',
  shareIntro: '智游乐山',
  shareImage: 'https://zyls-test2.oss-cn-chengdu.aliyuncs.com/峨眉山266d198a8-b1d6-4c96-81dd-afd28af1fb45.jpg',
}
